import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PRODUCTS_VIEW', {
  TITLE: 'Products',
  IMAGE: 'Image',
  NAME: 'Name',
  CATEGORIES: 'Categories',
  PRICE: 'Price',
  STOCK: 'Stock',
  SUPPLIER: 'Supplier',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete product',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the product {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PRODUCTS_VIEW', {
  TITLE: 'Productos',
  IMAGE: 'Imagen',
  NAME: 'Nombre',
  CATEGORIES: 'Categorías',
  PRICE: 'Precio',
  STOCK: 'Stock',
  SUPPLIER: 'Proveedor',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar producto',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar al producto {{ name }}?',
});
