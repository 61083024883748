import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { IAppState } from 'types/app.types';
import { ICategoriesState } from 'types/categories.types';
import { IProductsState } from 'types/products.types';
import { IUserState } from 'types/user.types';
import { IUsersState } from 'types/users.types';

export interface IRootState {
  app: IAppState;
  categories: ICategoriesState;
  products: IProductsState;
  user: IUserState;
  users: IUsersState;
}

const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default useTypedSelector;
