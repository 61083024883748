import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PRODUCTS_FORM', {
  TITLE: 'Products',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  CATEGORIES: 'Categories',
  TAGS: 'Tags',
  STOCK: 'Stock',
  PRICE: 'Price',
  SUPPLIER: 'Supplier',
  COST: 'Cost',
  IMAGE1: 'Image 1',
  IMAGE2: 'Image 2',
  IMAGE3: 'Image 3',
  IMAGE4: 'Image 4',
  IMAGE5: 'Image 5',
  IMAGE6: 'Image 6',
  COMBINATION: 'Combination',
  STOCK_CATEGORY: 'Stock Category',
  STOCK_DESCRIPTION: 'Stock Description',
  COMBINATION_BUTTON: 'Add combination',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PRODUCTS_FORM', {
  TITLE: 'Productos',
  NAME: 'Nombre',
  DESCRIPTION: 'Descripción',
  CATEGORIES: 'Categorías',
  TAGS: 'Etiquetas',
  STOCK: 'Stock',
  PRICE: 'Precio',
  SUPPLIER: 'Proveedor',
  COST: 'Costo',
  IMAGE1: 'Imagen 1',
  IMAGE2: 'Imagen 2',
  IMAGE3: 'Imagen 3',
  IMAGE4: 'Imagen 4',
  IMAGE5: 'Imagen 5',
  IMAGE6: 'Imagen 6',
  COMBINATION: 'Combinación',
  STOCK_CATEGORY: 'Stock Categoría',
  STOCK_DESCRIPTION: 'Stock Descripción',
  COMBINATION_BUTTON: 'Agregar combinación',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
