import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/products.actions';
import { productsSelector } from 'redux/selectors/products.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import ProductsView from 'views/ProductsView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { IProduct } from 'types/products.types';
import { ICategory } from 'types/categories.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/ProductsView/i18n';

const ProductsContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<IProduct | undefined>();
  const { count, loading, table } = useTypedSelector((state) => state.products);
  const items = useTypedSelector(productsSelector);

  const actions = [
    {
      label: i18next.t('PRODUCTS_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/products/${id}/edit`),
    },
    {
      label: i18next.t('PRODUCTS_VIEW:DELETE'),
      onClick: (id: string) =>
        setDeleteItem(items.find((item: IProduct) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'image1',
      label: i18next.t('PRODUCTS_VIEW:IMAGE'),
      options: {
        customBodyRender: (value: string) => {
          const item = items.find(({ image1 }: IProduct) => image1 === value);
          return <img alt={item?.name} height="100" src={item?.image1} />;
        },
        sort: false,
      },
    },
    {
      name: 'name',
      label: i18next.t('PRODUCTS_VIEW:NAME'),
    },
    {
      name: 'categories',
      label: i18next.t('PRODUCTS_VIEW:CATEGORIES'),
      options: {
        customBodyRender: (value: Array<ICategory>) =>
          value.map(({ name }: ICategory) => name).join(', '),
        sort: false,
      },
    },
    {
      name: 'price',
      label: i18next.t('PRODUCTS_VIEW:PRICE'),
    },
    {
      name: 'stock',
      label: i18next.t('PRODUCTS_VIEW:STOCK'),
    },
    {
      name: 'supplierName',
      label: i18next.t('PRODUCTS_VIEW:SUPPLIER'),
    },
    {
      name: 'id',
      label: i18next.t('PRODUCTS_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('PRODUCTS_VIEW:TITLE')}>
      <ProductsView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/products/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t('PRODUCTS_VIEW:DELETE_DIALOG_MESSAGE', {
          name: deleteItem?.name,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t('PRODUCTS_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default ProductsContainer;
