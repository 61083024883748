import { combineReducers } from 'redux';

import appReducer from 'redux/reducers/app.reducer';
import categoriesReducer from 'redux/reducers/categories.reducer';
import productsReducer from 'redux/reducers/products.reducer';
import userReducer from 'redux/reducers/user.reducer';
import usersReducer from 'redux/reducers/users.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  categories: categoriesReducer,
  products: productsReducer,
  user: userReducer,
  users: usersReducer,
});

export default rootReducer;
