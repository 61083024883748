import { createSelector } from 'reselect';

import { IUsersState } from 'types/users.types';

const getItems = (state: { users: IUsersState }) => state.users.items;
const getSelectedItem = (state: { users: IUsersState }) => state.users.selectedItem;

export const usersSelector = createSelector([getItems], (users) =>
  users.map((user) => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
  })),
);

export const usersFormSelector = createSelector([getSelectedItem], (user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
}));
