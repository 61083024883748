import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Field, useField } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Divider, IconButton } from '@mui/material';
import { AddCircleOutlined, DeleteOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import {
  composeValidators,
  fileValidation,
  requiredValidation,
  requiredArrayValidation,
} from 'helpers/validations';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';

import { ViewContainer, ViewContent, Title } from 'styles/view';
import {
  FormContent,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
} from 'styles/form';

import { IProductsFormProps } from './types';
import './i18n';

const combinationsField = {
  stock: '',
  stockCategory: '',
  stockDescription: '',
};

const stockCombinationsField = {
  combinations: [],
  ...combinationsField,
};

const ProductsForm: FunctionComponent<IProductsFormProps> = (props: IProductsFormProps) => {
  const {
    categories,
    initialValues,
    isEditing,
    files,
    onSubmit,
    stockCategories,
    stockDescriptions,
    suppliers,
    tags,
  } = props;

  const StockCategoryField = ({ name, parent }: { name: string; parent: string }) => {
    const field = useField(name);
    const parentField = useField(parent);
    const [options, setOptions] = useState(stockCategories);

    useEffect(() => {
      const array = stockCategories.filter(
        (stockCategory) => stockCategory.value !== parentField.input.value,
      );
      setOptions(array);
      const exists = array.some((stockCategory) => stockCategory.value === field.input.value);
      if (!exists) field.input.onChange('');
    }, [field, parentField]);

    return (
      <Field
        label={i18next.t<string>('PRODUCTS_FORM:STOCK_CATEGORY')}
        name={name}
        options={options}
        render={SelectInput}
        validate={requiredValidation}
      />
    );
  };

  const StockDescriptionField = ({ name, parent }: { name: string; parent: string }) => {
    const field = useField(name);
    const parentField = useField(parent);
    const [options, setOptions] = useState(stockDescriptions);

    useEffect(() => {
      const array = stockDescriptions.filter(
        (stockDescription) => stockDescription.data === parentField.input.value,
      );
      setOptions(array);
      const exists = array.some((stockDescription) => stockDescription.value === field.input.value);
      if (!exists) field.input.onChange('');
    }, [field, parentField]);

    return (
      <Field
        label={i18next.t<string>('PRODUCTS_FORM:STOCK_DESCRIPTION')}
        name={name}
        options={options}
        render={SelectInput}
        validate={requiredValidation}
      />
    );
  };

  const StockField = ({ name }: { name: string }) => {
    const field = useField(`${name}.combinations`);

    return !field.input.value?.length ? (
      <Field
        label={i18next.t<string>('PRODUCTS_FORM:STOCK')}
        name={`${name}.stock`}
        render={Input}
        type="number"
        validate={requiredValidation}
      />
    ) : null;
  };

  return (
    <ViewContainer auth data-testid="products-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('PRODUCTS_FORM:TITLE')}</Title>
        <Form
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove },
            },
            handleSubmit,
            submitting,
            pristine,
          }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:DESCRIPTION')}
                maxLength={65535}
                name="description"
                render={Input}
                type="textarea"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:CATEGORIES')}
                multiple
                name="categories"
                options={categories}
                render={SelectInput}
                validate={requiredArrayValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:TAGS')}
                multiple
                name="tags"
                options={tags}
                render={SelectInput}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:STOCK')}
                name="stock"
                render={Input}
                type="number"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:PRICE')}
                name="price"
                render={Input}
                type="number"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:SUPPLIER')}
                name="supplierId"
                options={suppliers}
                render={SelectInput}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:COST')}
                name="costo"
                render={Input}
                type="number"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE1')}
                name="image1"
                preview={files?.image1}
                render={Input}
                type="file"
                validate={
                  isEditing ? fileValidation : composeValidators(requiredValidation, fileValidation)
                }
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE2')}
                name="image2"
                preview={files?.image2}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE3')}
                name="image3"
                preview={files?.image3}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE4')}
                name="image4"
                preview={files?.image4}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE5')}
                name="image5"
                preview={files?.image5}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <Field
                label={i18next.t<string>('PRODUCTS_FORM:IMAGE6')}
                name="image6"
                preview={files?.image6}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <FieldArray name="stockCombinations">
                {({ fields: stockCombinations }) =>
                  stockCombinations.map((name, i) => (
                    <div key={name}>
                      <Divider>
                        {`${i18next.t<string>('PRODUCTS_FORM:COMBINATION')} ${i + 1}`}
                        <IconButton onClick={() => remove('stockCombinations', i)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Divider>
                      <FormRowContainer>
                        <Field
                          label={i18next.t<string>('PRODUCTS_FORM:STOCK_CATEGORY')}
                          name={`${name}.stockCategory`}
                          options={stockCategories}
                          render={SelectInput}
                          validate={requiredValidation}
                        />
                        <StockDescriptionField
                          name={`${name}.stockDescription`}
                          parent={`${name}.stockCategory`}
                        />
                        <StockField name={name} />
                        <IconButton
                          onClick={() => push(`${name}.combinations`, combinationsField)}
                          style={{ height: 60 }}
                        >
                          <AddCircleOutlined />
                        </IconButton>
                      </FormRowContainer>
                      <FieldArray name={`${name}.combinations`}>
                        {({ fields: combinations }) =>
                          combinations.map((name2, j) => (
                            <FormRowContainer key={name2}>
                              <StockCategoryField
                                name={`${name2}.stockCategory`}
                                parent={`${name}.stockCategory`}
                              />
                              <StockDescriptionField
                                name={`${name2}.stockDescription`}
                                parent={`${name2}.stockCategory`}
                              />
                              <Field
                                label={i18next.t<string>('PRODUCTS_FORM:STOCK')}
                                name={`${name2}.stock`}
                                render={Input}
                                type="number"
                                validate={requiredValidation}
                              />
                              <IconButton
                                onClick={() => remove(`${name}.combinations`, j)}
                                style={{ height: 60 }}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </FormRowContainer>
                          ))
                        }
                      </FieldArray>
                    </div>
                  ))
                }
              </FieldArray>
              <FormAddCombinationButton
                onClick={() => push('stockCombinations', stockCombinationsField)}
                variant="outlined"
              >
                {i18next.t<string>('PRODUCTS_FORM:COMBINATION_BUTTON')}
              </FormAddCombinationButton>
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('PRODUCTS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default ProductsForm;
