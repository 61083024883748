import { AxiosResponse } from 'axios';

import { get, post } from 'services/api';

import { ILoginFormData } from 'views/LoginView/types';

export const initialize = (): Promise<AxiosResponse> => get('users/profile');

export const login = (formData: ILoginFormData): Promise<AxiosResponse> =>
  post('auth/login', formData);

export const logout = (): Promise<AxiosResponse> => get('auth/logout');
