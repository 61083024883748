import styled from 'styled-components';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

import { ViewContainer, ViewContent } from 'styles/view';

export const NavbarContainer = styled(ViewContainer)`
  && {
    padding-bottom: 0;
  }
`;

export const NavbarContent = styled(ViewContent)`
  && {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    color: ${COLORS.grayScorpion};
  }
`;

export const NavbarMenuButton = styled(MenuIcon)`
  && {
    display: none;
    color: ${COLORS.grayScorpion};
    cursor: pointer;

    &:hover {
      color: ${COLORS.orangeOutrageous};
    }

    @media (max-width: ${SIZES.bigTablet}px) {
      display: block;
    }
  }
`;

export const NavbarAccountButton = styled(Button)`
  && {
    padding: 0;
  }
`;

export const NavbarProfileAvatar = styled(Avatar)`
  && {
    background-color: ${COLORS.orangeOutrageous};
    color: ${COLORS.white};
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const NavbarMenu = styled(Menu)`
  && {
    .MuiPaper-root {
      top: 80px !important;
      left: auto !important;
      right: 25px;

      @media (max-width: ${SIZES.bigTablet}px) {
        left: 25px !important;
      }
    }
  }
`;

export const NavbarMenuItem = styled(MenuItem)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
  }
`;

export const NavbarMenuItemLabel = styled.span`
  margin-left: 10px;
  color: ${COLORS.grayScorpion};
  font-family: 'Montserrat', Helvetica, Arial, serif;
  font-size: 14px;
  line-height: 20px;
`;
