import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/products.constants';
import { getAll, getForm, getOne, save, softDelete } from 'services/products.services';

export function* productsDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.PRODUCTS_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.PRODUCTS_ON_DELETE_FAILED, error });
  }
}

export function* productsGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.PRODUCTS_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCTS_ON_GET_ALL_FAILED, error });
  }
}

export function* productsGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.PRODUCTS_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCTS_ON_GET_FORM_FAILED, error });
  }
}

export function* productsGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.PRODUCTS_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.PRODUCTS_ON_GET_ONE_FAILED, error });
  }
}

export function* productsSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.PRODUCTS_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PRODUCTS_ON_SAVE_FAILED, error });
  }
}

export function* productsRefresh(): Generator {
  const table: any = yield select((state) => state.products.table);
  yield put({ type: constants.PRODUCTS_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchProducts(): Generator {
  yield all([
    takeLatest(constants.PRODUCTS_ON_DELETE_REQUESTED, productsDelete),
    takeLatest(constants.PRODUCTS_ON_GET_ALL_REQUESTED, productsGetAll),
    takeLatest(constants.PRODUCTS_ON_GET_FORM_REQUESTED, productsGetForm),
    takeLatest(constants.PRODUCTS_ON_GET_ONE_REQUESTED, productsGetOne),
    takeLatest(constants.PRODUCTS_ON_SAVE_REQUESTED, productsSave),
    takeLatest(
      [constants.PRODUCTS_ON_DELETE_SUCCEEDED, constants.PRODUCTS_ON_SAVE_SUCCEEDED],
      productsRefresh,
    ),
  ]);
}
