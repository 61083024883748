import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IProductsFormData } from 'views/ProductsView/components/ProductsForm/types';
import { onGetForm, onGetOne, onSave } from 'redux/actions/products.actions';
import {
  productsCategoriesSelector,
  productsFilesSelector,
  productsFormSelector,
  productsStockCategoriesSelector,
  productsStockDescriptionsSelector,
  productsSuppliersSelector,
  productsTagsSelector,
} from 'redux/selectors/products.selector';

import LayoutContainer from 'containers/LayoutContainer';
import ProductsForm from 'views/ProductsView/components/ProductsForm';
import Loading from 'components/commons/Loading';

import 'views/ProductsView/components/ProductsForm/i18n';

const ProductsFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(productsFormSelector);
  const files = useTypedSelector(productsFilesSelector);
  const { loading } = useTypedSelector((state) => state.products);
  const categories = useTypedSelector(productsCategoriesSelector);
  const stockCategories = useTypedSelector(productsStockCategoriesSelector);
  const stockDescriptions = useTypedSelector(productsStockDescriptionsSelector);
  const suppliers = useTypedSelector(productsSuppliersSelector);
  const tags = useTypedSelector(productsTagsSelector);
  const isEditing = !!id;

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IProductsFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('PRODUCTS_FORM:TITLE')}>
      <ProductsForm
        categories={categories}
        initialValues={initialValues}
        isEditing={isEditing}
        files={files}
        onSubmit={onSubmit}
        stockCategories={stockCategories}
        stockDescriptions={stockDescriptions}
        suppliers={suppliers}
        tags={tags}
      />
      {loading && <Loading isModalMode message={i18next.t('PRODUCTS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default ProductsFormContainer;
