import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ArticleOutlined, HomeOutlined, PersonOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';

import { isMenuOpenedSelector } from 'redux/selectors/app.selector';
import { onToggleMenu } from 'redux/actions/app.actions';

import Sidebar from 'components/main/Sidebar';

import 'components/main/Sidebar/i18n';

export interface ISidebarContainerProps {
  isMobile: boolean;
}

const SidebarContainer: FunctionComponent<ISidebarContainerProps> = (
  props: ISidebarContainerProps,
) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const isMenuOpened = useTypedSelector(isMenuOpenedSelector);
  const { pathname } = useLocation();

  const onCloseMenu = () => dispatch(onToggleMenu(false));

  useEffect(() => {
    if (isMobile && isMenuOpened) setTimeout(onCloseMenu, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const items = [
    {
      Icon: HomeOutlined,
      key: 'dashboard',
      label: i18next.t<string>('SIDEBAR:DASHBOARD'),
      to: '/dashboard',
    },
    {
      Icon: PersonOutlined,
      key: 'users',
      label: i18next.t<string>('SIDEBAR:USERS'),
      to: '/users',
    },
    {
      Icon: ArticleOutlined,
      key: 'products',
      label: i18next.t<string>('SIDEBAR:PRODUCTS'),
      to: '/products',
    },
  ];

  return (
    <Sidebar
      isMenuOpened={isMenuOpened}
      isMobile={isMobile}
      items={items}
      onCloseMenu={onCloseMenu}
    />
  );
};

export default SidebarContainer;
