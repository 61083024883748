import { Reducer } from 'redux';

import { IProductsState } from 'types/products.types';
import * as constants from 'redux/constants/products.constants';
import * as userConstants from 'redux/constants/user.constants';

export const defaultState: IProductsState = {
  categories: [],
  count: 0,
  items: [],
  loading: false,
  selectedItem: null,
  stockCategories: [],
  stockDescriptions: [],
  suppliers: [],
  table: {
    page: 1,
    limit: 10,
    search: '',
    sortKey: 'name',
    sortType: 'asc',
  },
  tags: [],
};

const productsReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.PRODUCTS_ON_DELETE_REQUESTED:
    case constants.PRODUCTS_ON_GET_ALL_REQUESTED:
    case constants.PRODUCTS_ON_GET_FORM_REQUESTED:
    case constants.PRODUCTS_ON_SAVE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.PRODUCTS_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
      };
    case constants.PRODUCTS_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        count: data.count,
        items: data.items,
        loading: false,
        table: data.table,
      };
    case constants.PRODUCTS_ON_GET_FORM_SUCCEEDED:
      return {
        ...state,
        categories: data.categories,
        loading: false,
        stockCategories: data.stockCategories,
        stockDescriptions: data.stockDescriptions,
        suppliers: data.suppliers,
        tags: data.tags,
      };
    case constants.PRODUCTS_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data.item,
      };
    case constants.PRODUCTS_ON_DELETE_SUCCEEDED:
    case constants.PRODUCTS_ON_SAVE_SUCCEEDED:
    case constants.PRODUCTS_ON_DELETE_FAILED:
    case constants.PRODUCTS_ON_GET_ALL_FAILED:
    case constants.PRODUCTS_ON_SAVE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.PRODUCTS_ON_GET_FORM_FAILED:
      return {
        ...state,
        categories: [],
        loading: false,
        stockCategories: [],
        stockDescriptions: [],
        suppliers: [],
        tags: [],
      };
    case constants.PRODUCTS_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    case userConstants.USER_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default productsReducer;
