import { all } from 'redux-saga/effects';

import { watchCategories } from 'redux/sagas/categories.sagas';
import { watchProducts } from 'redux/sagas/products.sagas';
import { watchUser } from 'redux/sagas/user.sagas';
import { watchUsers } from 'redux/sagas/users.sagas';

export default function* rootSaga(): Generator {
  yield all([watchCategories(), watchProducts(), watchUser(), watchUsers()]);
}
