import React, { useState, ChangeEvent, FunctionComponent } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { FILE_TYPES } from 'helpers/validations/constants';

import { FormGroup, ErrorBlock, HelpText } from 'styles/input';

import { IInputProps } from './types';
import { CustomInput, PreviewContainer, PreviewImage } from './styles';

import './i18n';

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const {
    disabled = false,
    input,
    className = '',
    label = '',
    helpText = '',
    maxLength = 255,
    maxRows = 3,
    meta: { touched, error },
    minRows = 3,
    preview = '',
    size = 'medium',
    variant = 'filled',
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { name, onChange, type = 'text', value } = input;
  const isFileInput = type === 'file';
  const isPasswordInput = type === 'password';
  const isTextAreaInput = type === 'textarea';
  const hasError = !!touched && !!error;

  const inputFileProps = isFileInput ? { accept: FILE_TYPES.join(',') } : {};

  const getInputType = () => {
    let inputType = type;
    if (isPasswordInput) {
      inputType = showPassword ? 'text' : type;
    }
    return inputType;
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (isFileInput && files?.length) onChange(files[0]);
    else onChange(event.target.value);
  };

  return (
    <>
      <FormGroup className={className}>
        <CustomInput
          {...input}
          disabled={disabled}
          error={hasError}
          id={name}
          inputProps={{
            'aria-label': name,
            'data-testid': `input-${name}`,
            maxLength,
            ...inputFileProps,
          }}
          label={label}
          maxRows={maxRows}
          minRows={minRows}
          multiline={isTextAreaInput}
          name={name}
          onChange={onInputChange}
          size={size}
          type={getInputType()}
          value={!isFileInput ? value : undefined}
          variant={variant}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: isPasswordInput && !disabled && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-testid="toggle-password-button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {!!helpText && <HelpText data-testid="help-text">{helpText}</HelpText>}
        {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
      </FormGroup>
      {isFileInput && preview && (
        <PreviewContainer>
          <span>{i18next.t<string>('INPUT:CURRENT_IMAGE')}:</span>
          <a href={preview} rel="noreferrer" target="_blank">
            <PreviewImage alt="preview" height="50" src={preview} />
          </a>
        </PreviewContainer>
      )}
    </>
  );
};

export default Input;
