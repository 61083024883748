import styled, { css } from 'styled-components';

import { SIZES } from 'helpers/enums/sizes';
import { COLORS } from 'helpers/enums/colors';

export const ViewContainer = styled.div<{ auth?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: ${({ auth }) => (auth ? 'auto' : '100%')};
  ${({ auth }) =>
    !auth &&
    css`
      justify-content: center;
      height: 100%;
      background: url('img/background.jpg') no-repeat center center;
      background-size: cover;
    `}
  padding: 25px;
`;

export const ViewContent = styled.div<{ auth?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ auth }) =>
    auth
      ? css`
          flex: 1;
          width: 100%;
        `
      : css`
          width: 50%;
        `}
  padding: 23px 24px;
  background-color: ${COLORS.white};
  border-radius: 6px;
  box-shadow: ${COLORS.boxShadow};

  ${({ auth }) =>
    !auth &&
    css`
      @media (max-width: ${SIZES.desktop}px) {
        width: 70%;
      }
    `}

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${SIZES.mediumPhone}px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  color: ${COLORS.orangeOutrageous};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${SIZES.smallTablet}px) {
    text-align: left;
  }

  @media (max-width: ${SIZES.mediumPhone}px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 24px;
  }
`;
