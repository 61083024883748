import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';
import { IProductsFormData } from 'views/ProductsView/components/ProductsForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `products?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getForm = (): Promise<AxiosResponse> => get('products/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`products/${id}`);

export const save = (data: IProductsFormData, id: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('categories', data.categories);
  formData.append('tags', data.tags);
  formData.append('stock', data.stock);
  formData.append('price', data.price);
  formData.append('supplierId', data.supplierId);
  formData.append('costo', data.costo);
  if (data.image1) formData.append('image1', data.image1);
  if (data.image2) formData.append('image2', data.image2);
  if (data.image3) formData.append('image3', data.image3);
  if (data.image4) formData.append('image4', data.image4);
  if (data.image5) formData.append('image5', data.image5);
  if (data.image6) formData.append('image6', data.image6);
  if (data.stockCombinations)
    formData.append('stockCombinations', JSON.stringify(data.stockCombinations));
  if (id) formData.append('_method', 'PUT');
  return post(`products${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`products/${id}`);
