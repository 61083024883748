import { Middleware } from 'redux';

import * as categoriesConstants from 'redux/constants/categories.constants';
import * as productsConstants from 'redux/constants/products.constants';
import * as userConstants from 'redux/constants/user.constants';
import * as usersConstants from 'redux/constants/users.constants';
import { history } from 'helpers/history';

const redirectMiddleware: Middleware = () => (next) => (action) => {
  const { error, pathname, type } = action;
  switch (type) {
    case userConstants.USER_ON_LOGIN_SUCCEEDED:
      setTimeout(() => history.push(pathname || '/'), 0);
      break;
    case userConstants.USER_ON_LOGOUT_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_FAILED:
      setTimeout(() => history.push('/login'), 0);
      break;
    case categoriesConstants.CATEGORIES_ON_SAVE_SUCCEEDED:
      history.push('/categories');
      break;
    case productsConstants.PRODUCTS_ON_SAVE_SUCCEEDED:
      history.push('/products');
      break;
    case usersConstants.USERS_ON_SAVE_SUCCEEDED:
      history.push('/users');
      break;
    case userConstants.USER_ON_LOGIN_FAILED:
      break;
    default:
      if (error?.response?.status === 401) setTimeout(() => history.push('/'), 0);
      break;
  }
  return next(action);
};

export default redirectMiddleware;
