import { AnyAction } from 'redux';

import * as constants from 'redux/constants/products.constants';
import { ITableUpdate } from 'types/table.types';
import { IProductsFormData } from 'views/ProductsView/components/ProductsForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.PRODUCTS_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.PRODUCTS_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetForm = (): AnyAction => ({
  type: constants.PRODUCTS_ON_GET_FORM_REQUESTED,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.PRODUCTS_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IProductsFormData, id?: string): AnyAction => ({
  type: constants.PRODUCTS_ON_SAVE_REQUESTED,
  formData,
  id,
});
