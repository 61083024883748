import React, { FunctionComponent } from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';

import Route from 'containers/RouteContainer';
import LoginContainer from 'containers/LoginContainer';
import DashboardContainer from 'containers/DashboardContainer';
import ProductsContainer from 'containers/ProductsContainer';
import ProductsFormContainer from 'containers/ProductsFormContainer';
import UsersContainer from 'containers/UsersContainer';
import UsersFormContainer from 'containers/UsersFormContainer';
import ErrorView from 'views/ErrorView';

const NavigationContainer: FunctionComponent<SwitchProps> = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    {/* Public Routes */}
    <Route exact path="/login" component={LoginContainer} restricted />
    {/* Private Routes */}
    <Route exact path="/dashboard" component={DashboardContainer} authenticated />
    <Route exact path="/products" component={ProductsContainer} authenticated />
    <Route exact path="/products/create" component={ProductsFormContainer} authenticated />
    <Route exact path="/products/:id/edit" component={ProductsFormContainer} authenticated />
    <Route exact path="/users" component={UsersContainer} authenticated />
    <Route exact path="/users/create" component={UsersFormContainer} authenticated />
    <Route exact path="/users/:id/edit" component={UsersFormContainer} authenticated />
    {/* Default Route */}
    <Route component={ErrorView} />
  </Switch>
);

export default NavigationContainer;
