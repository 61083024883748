import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'SIDEBAR', {
  DASHBOARD: 'Dashboard',
  USERS: 'Users',
  PRODUCTS: 'Products',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'SIDEBAR', {
  DASHBOARD: 'Dashboard',
  USERS: 'Usuarios',
  PRODUCTS: 'Productos',
});
